.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  position: relative;
  height: 50px;
  padding: 5px;
  // margin: 16px;
  // background: rgba(255, 255, 255, 0.3);
}
.logo_img {
  height: 100%;
  display: block;
  margin: 0 auto;
}

.site-layout .site-layout-background {
  background: #fff;
}

@primary-color: #1890ff;@font-size-base: 13px;