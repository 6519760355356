// @import "~antd/dist/antd.less";

h2 {
  color: @primary-color;
  text-align: center;
}

.form {
  // margin-top: 50px;
  // width: 700px;
  width: 100%;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  p {
    color: rgb(204, 204, 204);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  .input-icon {
    color: rgba(0, 0, 0, 0.25);
    margin-right: 4px;
  }
}

@primary-color: #1890ff;@font-size-base: 13px;